import { useState } from 'react'
import './App.css'
import axios from "axios"

function getParams() {
    return (window.location.search || "?")
        .replace('?', '')
        .split('&')
        .reduce<{ [key: string]: string }>((prev, curr) => {
            const pair = curr.split('=')
            if (pair.length === 2) {
                const key = pair[0]
                const value = decodeURIComponent(pair[1])
                if (key === "sek") { prev.sentEmailKey = value }
                if (key === "e") { prev.email = value }
                if (key === "c") { prev.campaignKey = value }
            }
            return prev
        }, {})
}

function NewUnsub() {
    const { email, sentEmailKey, campaignKey } = getParams()
    const ok = !!(sentEmailKey && campaignKey && email)
    const [inProgress, setInProgress] = useState(false)
    const [complete, setComplete] = useState(false)

    async function handleUnsubscribe() {
        setInProgress(true)
        try {
            await axios.post("/unsub", { sentEmailKey, email, campaignKey })
            setComplete(true)
        }
        catch (e) {
            console.log(e)
        }
        setInProgress(false)
    }

    return <div className="App">
        <header className="App-header">
            <h1>Unsubscribe</h1>
            {
                ok &&
                <div>
                    {
                        complete &&
                        <>
                            <p>{email} has been unsubscribed.</p>
                            <p>Thank you</p>
                        </>
                    }
                    {
                        inProgress && !complete &&
                        <p>
                            Unsubscribing {email} ...
                        </p>
                    }
                    {
                        !inProgress && !complete &&
                        <p>
                            <button onClick={handleUnsubscribe}>Unsubscribe {email}</button>
                        </p>
                    }
                </div>
            }
            {
                !ok &&
                <div>
                    <p>Thank you</p>
                </div>
            }
        </header>
    </div>
}

export default NewUnsub