import { useState } from 'react'
import './App.css'
import axios from "axios"

function getParams() {
  return (window.location.search || "?")
    .replace('?', '')
    .split('&')
    .reduce<{ [key: string]: string }>((prev, curr) => {
      const pair = curr.split('=')
      if (pair.length === 2) {
        const key = pair[0]
        const value = decodeURIComponent(pair[1])
        if (key === "e") { prev.email = value }
        if (key === "c") { prev.campaignKey = value }
        if (key === "b") { prev.brandKey = value }
        if (key === "o") { prev.operationId = value }
        if (key === "t") { prev.emailTemplateKey = value }
      }
      return prev
    }, {})
}

function App() {

  const { email, campaignKey, brandKey, operationId, emailTemplateKey } = getParams()
  const ok = email && campaignKey && brandKey && operationId && emailTemplateKey
  const [inProgress, setInProgress] = useState(false)
  const [complete, setComplete] = useState(false)

  async function handleUnsubscribe() {
    setInProgress(true)
    try {
      await axios.post("/unsubscribe", { email, campaignKey, /*brandKey,*/ operationId, emailTemplateKey })
      setComplete(true)
    }
    catch (e) {
      console.log(e)
    }
    setInProgress(false)
  }

  return <div className="App">
    <header className="App-header">
      <h1>Unsubscribe</h1>
      {
        ok &&
        <div>
          {
            complete &&
            <>
              <p>{email} has been unsubscribed.</p>
              <p>Thank you</p>
            </>
          }
          {
            inProgress && !complete &&
            <p>
              Unsubscribing {email} ...
            </p>
          }
          {
            !inProgress && !complete &&
            <p>
              <button onClick={handleUnsubscribe}>Unsubscribe {email}</button>
            </p>
          }
        </div>
      }
      {
        !ok &&
        <div>
          <p>Thank you</p>
        </div>
      }
    </header>
  </div>
}

export default App;
